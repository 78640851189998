const ga4_id = 'G-MYG2FWNQWS'

const consentObj = (consent = false) => ({
  ad_storage: consent ? 'granted' : 'denied',
  ad_user_data: consent ? 'granted' : 'denied',
  ad_personalization: consent ? 'granted' : 'denied',
  analytics_storage: consent ? 'granted' : 'denied',
})

const loadGtagScript = (router) => {
  if (window.gtag) return

  window.dataLayer = window.dataLayer || []
  function gtag() {
    window.dataLayer.push(arguments)
  }
  window.gtag = gtag
  window.gtag('consent', 'default', consentObj())
  window.gtag('js', new Date())
  window.gtag('config', ga4_id, { anonymize_ip: true, send_page_view: false })

  router.afterEach((to) => {
    window.gtag('config', ga4_id, { page_path: to.fullPath })
  })

  const scriptTag = document.createElement('script')
  scriptTag.src = `https://www.googletagmanager.com/gtag/js?id=${ga4_id}`
  scriptTag.async = true
  document.head.appendChild(scriptTag)
}

const enableGtagTracking = (enable = true) => {
  window.gtag('consent', 'update', consentObj(enable))
}

const initGA4 = (router) => {
  loadGtagScript(router)

  enableGtagTracking()
}

export { enableGtagTracking, initGA4 }
