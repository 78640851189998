import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { enableGtagTracking, initGA4 } from './gtag.client'

initGA4(router)
enableGtagTracking()

import 'bootstrap/dist/js/bootstrap.js'
import 'bootstrap/dist/css/bootstrap.css'
import './assets/scss/main.scss'
// lp 2
import './assets/scss/main2.scss'
// AA
import './assets/scss/mainAA.scss'

createApp(App).use(router).mount('#app')
